<template>
    <div>
        <div class="point_withdraw_wrap">
            <div class="p_title">{{ $t('my_bank') }}</div>
            <div class="info_box">
                <div class="point_input_box">
                    <div class="info_text">{{ $t('bank_name') }}</div>
                    <div class="info_input info_div">{{ bkName }}</div>
                    <div class="modify_btn" @click="movePage('/mypage/update')"
                         style="position: absolute;right: 10px;top: 50%;transform: translateY(-50%);"></div>
                </div>

                <div class="point_input_box">
                    <div class="info_text">{{ $t('account_number') }}</div>
                    <div class="info_input info_div">{{ acNumber }}</div>
                </div>

                <div class="point_input_box">
                    <div class="info_text">{{ $t('account_holder') }}</div>
                    <div class="info_input info_div">{{ acName }}</div>
                </div>
            </div>

            <div class="p_title">{{ $t('withdrawal_re') }}</div>
            <div class="info_box">

                <div class="point_input_box">
                    <div class="info_text">{{ $t('withdraw_possible_mingle') }}</div>
                    <div class="info_input info_div div_mg">{{ returnCommas(haveMg) }}</div>
                </div>

                <div class="point_input_box">
                    <div class="info_text">{{ $t('withdraw__mingle') }}</div>
                    <el-input class="info_input input_mg el_input_num"
                              type="tel"
                              v-model="point"
                              :placeholder="$t('withdraw_err1')"
                              @input="returnNumber('point',point)"
                    />
                </div>

                <div class="point_input_box">
                    <div class="info_text">{{ $t('w_after_mingle') }}</div>
                    <div class="info_input info_div div_mg">{{ returnAfterMg() }}</div>
                </div>

                <div class="validation" v-if="validation.hasError('point')">
                    {{ validation.firstError('point') }}
                </div>
                <div class="validation" v-if="!validation.hasError('point') && maxPriceErrorMsg">
                    {{ maxPriceErrorMsg }}
                </div>
            </div>

            <div class="withdraw_notification_wrap">
                <el-checkbox v-model="noticeCheck" @click="changeTerms">
              <span class="terms_text item_text_span" v-html="$t('withdraw_exp3')"
                    @click="openModal('terms-modal', 'withdraw')">
            </span>
                </el-checkbox>
            </div>
            <div class="confirm_btn_wrap">
                <button class="btn save" @click="Withdraw()">{{ $t('withdrawal_re') }}</button>
            </div>

        </div>
    </div>
</template>

<script>

import {mapState} from "vuex";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";
import priceMixins from "@/mixins/priceMixins";
import pointValidator from "@/mixins/validators/pointValidator";

export default {
    name: "PointWriteWithdrawLayout",
    mixins: [alertMixins, priceMixins, pointValidator],
    components: {},
    inject: ['pointSetData'],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            bkName: '',
            acNumber: '',
            acName: '',
            haveMg: '',
            point: '',
            afterMg: '',
            msgTemp: [],
            minimumSetting: 0,
            noticeCheck: false,
        }
    },
    beforeRouterEnter() {
    },
    async created() {
        openTerms = this.openTerms;
    },
    mounted() {
        this.initData();
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        initData() {
            if (!util.isEmpty(this.UserInfo.Account)) {
                this.bkName = this.UserInfo.Account[0].ma_bank;
                this.acNumber = this.UserInfo.Account[0].ma_account;
                this.acName = this.UserInfo.Account[0].ma_name;
            }
            this.haveMg = this.UserInfo.point;
        },
        movePage(url) {
            this.$router.push(url);
        },
        openModal(data, type) {
            this.$modal.show(`${data}`, {termsType: type});
        },

        returnNumber(type, price) {
            let number = this.returnMaxPrice(price);
            if (number > Number(this.haveMg)) {
                number = Number(this.haveMg);
            }
            this.pointSetData('point', number)
            this[type] = this.returnCommas(number);
        },
        returnAfterMg() {
            let total = 0;
            let point = this.returnMaxPrice(this.point);
            total = Number(this.haveMg) - Number(point);
            return this.returnCommas(total);
        },
        Withdraw() {
            if (util.isEmpty(this.UserInfo.Account)) {
                this.errorAlert(this.$t('popup_bank'))
                return
            }
            if (this.UserInfo.g_idx != 2) {
                if (this.UserInfo.Account[0].ma_name != this.UserInfo.mb_name) {
                    this.errorAlert(this.$t('account_warning'));
                    return false;
                }
            }
            if (this.noticeCheck) {
                EventBus.$emit('withdraw');
            } else {
                this.errorAlert(this.$t('withdraw_exp'))
            }

        },
        changeTerms() {
            this.noticeCheck = !this.noticeCheck;
        },
        openTerms(name, type) {
            this.$modal.show(name, {termsType: type})
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
